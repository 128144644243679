<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal
        id="approvalModal"
        ref="approvalModal"
        size="lg"
        :title="'Approuver la requête ' + this.$props.modelRef"
        title-class="font-18"
        hide-footer
      >
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-md-12">
              <label for="formrow-inputCity">Site *</label>
              <multiselect
                  v-model="aprovalForm.campId"
                  :searchable="true"
                  track-by="id"
                  label="name"
                  :options="user.lifebase"
                  placeholder="Sélectionner le site"
                  :allow-empty="false"
                  :class="{'is-invalid': submitted && $v.aprovalForm.campId.$error,}"
                >
                  <template slot="singleCamp" slot-scope="{ camp }">{{
                    camp.name
                  }}</template>
                </multiselect>
                <div v-if="submitted && $v.aprovalForm.campId.$error" class="invalid-feedback">
                  <span v-if="!$v.aprovalForm.campId.required">Champ Obligatoire.</span>
                </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="formrow-inputCity">Commentaires *</label>
                <textarea
                  v-model="aprovalForm.comments"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>

          <div>
            <button class="btn btn-primary" type="submit">
              Valider
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from "vue-multiselect";
import { required , requiredIf } from "vuelidate/lib/validators";
export default {
  props: {
    modelUid: undefined,
    modelRef: {
      type: String,
    },
  },
  components: {Multiselect},
  data() {
    return {
      show: true,
      submitted : false,
      aprovalForm: {
        campId: []
      },
    };
  },
      
  validations: {
      aprovalForm:{
        campId: { required },
      }
  },

  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    })
  },
  methods: {
    submit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitted = false
        this.aprovalForm.requestUid = this.$props.modelUid;
        var _this = this;
        this.$http
        .post("/ops/requests/dotation/approve", this.aprovalForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$refs['approvalModal'].hide()
              this.$emit('reloadOrders',true);
              this.$emit('resetForm',true);
              _this.$toast.success(res.data.original.msg);
              break;

            case 500:
              _this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          _this.$toast.warning(error.message);
        })
        .finally(() => {});
      }
    },
  },
};
</script>
